/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@app/plugins/store";
import auth_routes from './auth';
import email_routes from './email';
import app_routes from './app';
import other_routes from './other';

Vue.use(VueRouter)

const routes = [
    auth_routes,
    email_routes,
    app_routes,
    other_routes
]

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    }
})

router.beforeEach((to, from, next) => {
    store.dispatch('auth/check')
        .then((user) => {
            if (user.email_verified) {
                if (to.matched[0].name === 'Auth' && store.getters['temp/getAccountType']()) {
                    next({name: 'App'})
                } else if (to.matched[0].name === 'Email') {
                    next({name: 'App'})
                } else {
                    next()
                }
            } else {
                if (to.matched[0].name === 'Email') {
                    next()
                } else {
                    next({name: 'EmailVerification'})
                }
            }
        })
        .catch(() => {
            if (to.matched.length > 0 && to.matched[0].name === 'App') {
                next({name: 'Login'})
            } else if (to.matched.length > 0 && (to.matched[0].name === 'Auth' || to.matched[0].name === 'Other')) {
                next()
            } else if (to.name !== 'Login') {
                next({name: 'Login'})
            } else {
                next()
            }
        });
})

export default router
