import {HTTP} from '@shared/plugins/axios'

export const NotificationsModule = {
    namespaced: true,
    state: () => ({}),
    getters: {},
    mutations: {},
    actions: {
        send: function (_, data) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("notifications/send", data)
                    .then(function () {
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
    }
}
