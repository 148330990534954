import Vue from "vue";

let snackbar = {
    visible: false,
    text: "",
    showText: function (text) {
        this.text = text;
        this.visible = true;
    }
};

export function showSnackbar(text) {
    snackbar.text = text;
    snackbar.visible = true;
}

Vue.use({
    install: function () {
        Object.defineProperty(Vue.prototype, '$snackbar', {
            get() {
                return snackbar
            }
        })
    }
});


export default snackbar;
