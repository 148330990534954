import {HTTP} from '@shared/plugins/axios'

export const CustomersExperienceModule = {
    namespaced: true,
    state: () => ({
        list: null,
        professions: null,
        new_list: [],
        deleted_list: [],
    }),
    getters: {
        getList: (state) => () => {
            return state.list
        },
        getProfessions: (state) => () => {
            return state.professions
        },
    },
    mutations: {
        setList: function (state, list) {
            state.list = list;
            state.new_list = [];
            state.deleted_list = [];
        },
        setNewList: function (state, list) {
            state.new_list = list;
        },
        setProfessions: function (state, professions) {
            state.professions = professions;
        },
        addItem: function (state, item) {
            state.list.push(item);
            state.new_list.push(item);
        },
        removeItem: function (state, index) {
            state.deleted_list.push(state.list.splice(index, 1)[0]);
        },
    },
    actions: {
        loadList: function ({commit}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .get("customer-experience/get")
                    .then(function (response) {
                        commit("setList", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        loadProfessions: function ({commit}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("criteria/professions", {
                        query: '',
                    })
                    .then(function (response) {
                        commit("setProfessions", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        save: function ({commit, state}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("customer-experience/save-new", {
                        experience_new: state.new_list,
                        experience_deleted: state.deleted_list
                    })
                    .then(function (response) {
                        commit("setList", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
    }
}
