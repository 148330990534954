import {HTTP} from '@shared/plugins/axios'
import {objectToFormData} from "object-to-formdata";

export const PersonalitiesModule = {
    namespaced: true,
    state: () => ({
        list: [],
        selected: null,
        total: 0,
    }),
    getters: {
        getTotal: (state) => () => {
            return state.total
        },
        getAll: (state) => () => {
            return state.list
        },
        getSelected: (state) => () => {
            return state.selected
        }
    },
    mutations: {
        save: function (state, item) {
            let index = state.list.findIndex(el => el.id == item.id)
            if (index !== -1) {
                state.list.splice(index, 1, item);
            } else {
                state.list.push(item);
            }
        },
        delete: function (state, item) {
            let index = state.list.findIndex(el => el.id == item.id)
            if (index !== -1) {
                state.list.splice(index, 1);
                state.total -= 1;
            }
        },
        setList: function (state, list) {
            state.list = list;
        },
        setTotal: function (state, total) {
            state.total = total;
        },
        setSelected: function (state, selected) {
            state.selected = selected;
        },
    },
    actions: {
        save: function ({commit}, item) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("personalities/save", item)
                    .then(function (response) {
                        commit("save", response.data)
                        commit("setSelected", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        delete: function ({commit}, item) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("personalities/delete", {
                        id: item.id
                    })
                    .then(function () {
                        commit("delete", item)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        filter: function ({commit}, data) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("personalities/filter", data)
                    .then(function (response) {
                        commit("setList", response.data.items)
                        commit("setTotal", response.data.total)
                        resolve();
                    })
                    .catch(function (response) {
                        commit("setList", [])
                        commit("setTotal", 0)
                        reject(response);
                    })
            })
        },
        loadById: function ({commit}, id) {
            return new Promise((resolve, reject) => {
                if (id) {
                    HTTP
                        .get("personalities/get/" + id)
                        .then(function (response) {
                            commit("setSelected", response.data)
                            resolve();
                        })
                        .catch(function (response) {
                            reject(response);
                        })
                } else {
                    commit("setSelected", null)
                    resolve()
                }
            })
        },
        export: function () {
            return new Promise((resolve, reject) => {
                HTTP
                    .get("personalities/export", {
                        responseType: 'arraybuffer',
                    })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');

                        link.href = url;
                        link.setAttribute('download', 'personalities.xlsx');
                        document.body.appendChild(link);

                        link.click();
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        import: function (_, file) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("personalities/import", objectToFormData({file}))
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
    }
}
