import {HTTP} from '@shared/plugins/axios'

export const ChatsModule = {
    namespaced: true,
    state: () => ({
        list: [],
        messages: [],
        selected: null,
    }),
    getters: {
        getAll: (state) => () => {
            return state.list
        },
        getSelected: (state) => () => {
            return state.selected
        },
        getMessages: (state) => () => {
            return state.messages
        },
        getLastMessage: (state) => () => {
            return state.messages[0]
        }
    },
    mutations: {
        setList: function (state, list) {
            state.list = list;
        },
        setSelected: function (state, selected) {
            state.selected = selected;
            state.messages = [];
        },
        addMessages: function (state, messages) {
            state.messages.splice(0, 0, ...messages.reverse());
        },
        insertMessage: function (state, message) {
            state.messages.push(message);
        },
    },
    actions: {
        loadAll: function ({commit}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .get("chat/all")
                    .then(function (response) {
                        commit("setList", response.data)
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        search: function ({commit}, query) {
            return new Promise((resolve, reject) => {
                    HTTP
                        .post("chat/search", {
                                query: query || ''
                            }
                        )
                        .then(function (response) {
                            commit("setList", response.data)
                            resolve(response.data);
                        })
                        .catch(function (response) {
                            reject(response);
                        })
                }
            )
        },
        loadById: function ({commit}, id) {
            return new Promise((resolve, reject) => {
                    HTTP
                        .get("chat/get/" + id)
                        .then(function (response) {
                            commit("setSelected", response.data)
                            resolve(response.data);
                        })
                        .catch(function (response) {
                            reject(response);
                        })
                }
            )
        },
        loadMessages: function ({commit, getters}) {
            return new Promise((resolve, reject) => {
                    HTTP
                        .post("chat/messages", {
                            chat: getters['getSelected']().id,
                        })
                        .then(function (response) {
                            commit("addMessages", response.data)
                            resolve(response.data);
                        })
                        .catch(function (response) {
                            reject(response);
                        })
                }
            )
        },
        loadMoreMessages: function ({commit, getters}, beforeMessage) {
            return new Promise((resolve, reject) => {
                    HTTP
                        .post("chat/messages", {
                            chat: getters['getSelected']().id,
                            last_message: beforeMessage ? undefined : getters['getLastMessage']().sent_at,
                            before_message: beforeMessage,
                        })
                        .then(function (response) {
                            if (beforeMessage && response.data) {
                                response.data.forEach((e) => {
                                    commit("insertMessage", e)
                                });
                            } else {
                                commit("addMessages", response.data)
                            }
                            resolve(response.data);
                        })
                        .catch(function (response) {
                            reject(response);
                        })
                }
            )
        },
        send: function ({getters}, text) {
            return new Promise((resolve, reject) => {
                    HTTP
                        .post("chat/send", {
                            text: text,
                            chat: getters['getSelected']().id,

                        })
                        .then(function (response) {
                            resolve(response.data);
                        })
                        .catch(function (response) {
                            reject(response);
                        })
                }
            )
        },
        seen: function ({getters}) {
            return new Promise((resolve, reject) => {
                    HTTP
                        .post("chat/seen", {
                            chat: getters['getSelected']().id,
                        })
                        .then(function (response) {
                            resolve(response.data);
                        })
                        .catch(function (response) {
                            reject(response);
                        })
                }
            )
        },
    }
}
