const auth_routes = {
    path: '/',
    name: 'Auth',
    redirect: {
        name: 'Login'
    },
    component: () => import('../../views/auth/Layout.vue'),
    children: [
        {
            path: 'login',
            name: 'Login',
            component: () => import('../../views/auth/Login.vue')
        },
        {
            path: 'register',
            name: 'Register',
            component: () => import('../../views/auth/Register.vue')
        },
        {
            path: 'forgot-password',
            name: 'ForgotPassword',
            component: () => import('../../views/auth/ForgotPassword.vue')
        },
        {
            path: 'reset-password/:token',
            name: 'ResetPassword',
            component: () => import('../../views/auth/ResetPassword.vue')
        },
        {
            path: 'otl/:token',
            name: 'OTL',
            component: () => import('../../views/auth/OTL.vue')
        },
    ],
}

export default auth_routes
