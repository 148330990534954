import Vue from 'vue';
import moment from 'moment';
import translations from "./translations";
import constants from "../constants";
import {trim} from 'lodash';

const initialsRegex = /(?:^|\s|-)+([^\s-])[^\s-]*(?:(?:\s+)(?:the\s+)?(?:jr|sr|II|2nd|III|3rd|IV|4th)\.?$)?/gi;

Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
})

Vue.filter('uppercase', function (value) {
    if (!value) return ''
    return value.toUpperCase()
})

Vue.filter('lowercase', function (value) {
    if (!value) return ''
    return value.toLowerCase()
})

Vue.filter('initials', function (value) {
    if (!value) return ''
    let matches = value.matchAll(initialsRegex);
    let result = "";
    let count = 0;
    for (let match of matches) {
        count++;
        result += match[1];
        if (count >= 3) {
            break;
        }
    }
    return result.toUpperCase();
})

Vue.filter('date', function (value, format) {
    if (value) {
        return moment.utc(value).format(format);
    }
    return null;
})

Vue.filter('ago', function (value) {
    return moment.utc(value).fromNow();
})

Vue.filter('message', function (value) {
    if (value) {
        if (value.type === 'REQUEST' && value.request != null) {
            return translations.translateAll('message_request', value.request.status.id === constants.STATUS_ACCEPTED ? 'accepted' : 'declined', value.text);
        }
        return value.text;
    }
    return '';
})

Vue.filter('availability', function (value) {
    if (value) {
        let timeAvailability = "";
        if (value.time_from && value.time_to) {
            timeAvailability = `${moment.utc(value.time_from, 'HH:mm:ss').format('HH:mm')} - ${moment.utc(value.time_to, 'HH:mm:ss').format('HH :mm')}`;
        }
        return trim(`${value.weekly_hours ? translations.translate('duration_hours_per_week', value.weekly_hours) : ''} ${timeAvailability}`)
    }
    return translations.translate('time_availability_empty');
})

Vue.filter('timer', function (value) {
    const seconds = parseInt(value);
    const minutes = Math.floor(seconds / 60.0);
    const remainingSeconds = seconds - minutes * 60;
    return `${("0" + minutes).substr(-2)}:${("0" + remainingSeconds).substr(-2)}`;
})

Vue.filter('translate', function (value, ...args) {
    return translations.translateAll(value, ...args);
})

Vue.filter('customer_experience', function (value) {
    if (value) {
        if (value.start != null && (value.end != null || value.still_working)) {
            if (value.still_working) {
                return translations.translate('experience_from_to', moment.utc(value.start).format('DD.MM.YYYY'), translations.translate('today'), value.name);
            } else {
                return translations.translate('experience_from_to', moment.utc(value.start).format('DD.MM.YYYY'), moment.utc(value.end).format('DD.MM.YYYY'), value.name);
            }
        } else {
            return translations.translate('experience_length', value.months, value.name);
        }
    }
    return null;
})

export default {}
