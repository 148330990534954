import axios from "axios";
import Vue from "vue";
import {showSnackbar} from "./snackbar";

const path = (window.APP_PATH ?? '') + '/';
const axiosInstance = axios.create({
    baseURL: `${window.API_URL}${window.APP_PATH}`,
})

axiosInstance.interceptors.request.use(
    function (config) {
        let token = localStorage.getItem(`${path}TOKEN`);
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        let locale = localStorage.getItem(`${path}LOCALE`);
        if (locale) {
            config.headers["Accept-Language"] = locale;
        }
        return config;
    }, function (error) {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(function (response) {
    if (response && response.data && response.data.message) {
        if (Object.keys(response.data).length === 1) {
            showSnackbar(response.data.message)
        }
    }
    return response;
}, function (error) {
    if (error.response) {
        let data = error.response.data;
        if (error.response.status === 422 && data) {
            showSnackbar(data[Object.keys(data)[0]][0])
        } else if (error.response.status === 500) {
            showSnackbar("Error while processing your request")
        } else if (error.response.status === 403) {
            showSnackbar(data.message)
        } else if (Object.keys(data).length === 1 && data.message) {
            showSnackbar(data.message)
        }
    }
    return Promise.reject(error);
});

Vue.use({
    install: function () {
        Object.defineProperty(Vue.prototype, '$http', {
            get() {
                return axiosInstance
            }
        })
    }
});

export const HTTP = axiosInstance;

export default axiosInstance;
