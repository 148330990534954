import {HTTP} from '@shared/plugins/axios'

export const SettingsModule = {
    namespaced: true,
    state: () => ({
        languages: [],
    }),
    getters: {
        getLanguages: (state) => () => {
            return state.languages
        },
    },
    mutations: {
        setLanguages: function (state, languages) {
            state.languages = languages;
        },
    },
    actions: {
        loadLanguages: function ({commit}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .get("languages/translate")
                    .then(function (response) {
                        commit("setLanguages", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
    }
}
