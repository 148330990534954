import {HTTP} from '@shared/plugins/axios'

export const MatchesModule = {
    namespaced: true,
    state: () => ({
        list: [],
        selected: null,
    }),
    getters: {
        getAll: (state) => () => {
            return state.list
        },
        getSelected: (state) => () => {
            return state.selected
        },
        getCriteria: (state, _, __, rootGetters) => () => {
            const criteria = rootGetters['temp/getCriteria']();
            return {
                category: (criteria.category || {}).id,
                profession: (criteria.profession || {}).id,
                query: criteria.query,
                competencies: (criteria.competencies || []).map(el => el.id),
                personalities: (criteria.personalities || []).map(el => el.id),
                languages: (criteria.languages || []).map(el => el.id),
                language_type: (criteria.language_type || {}).id,
                country: (criteria.country || {}).id,
                city: (criteria.city || {}).id,
                exclude_in_process: !!criteria.exclude_in_process,
                more_than_30: !!criteria.more_than_30,
                less_than_30: !!criteria.less_than_30,
                part_time: !!criteria.part_time,
                full_time: !!criteria.full_time,
                age_from: criteria.age_from,
                age_to: criteria.age_to,
            }
        },
    },
    mutations: {
        setList: function (state, list) {
            state.list = list;
        },
        setSelected: function (state, selected) {
            state.selected = selected;
        },
    },
    actions: {
        search: function ({commit, getters}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("matches/search", getters['getCriteria']())
                    .then(function (response) {
                        commit("setList", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        commit("setList", [])
                        reject(response);
                    })
            })
        },
        send: function (_, data) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("request/send", data)
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
    }
}
