import Vue from 'vue'
import App from './App.vue'
import constants from "./plugins/constants";
import router from './plugins/router'
import axios from "@shared/plugins/axios";
import vue_editor from "@shared/plugins/vue_editor";
import vue_moment from "@shared/plugins/vue_moment";
import globals from "@shared/plugins/globals";
import validation_rules from "@shared/plugins/validation_rules";
import translations from "@shared/plugins/translations";
import snackbar from "@shared/plugins/snackbar";
import filters from "@shared/plugins/filters";
import firebase from "./plugins/firebase";
import vuetify from "./plugins/vuetify";
import store from "./plugins/store";
import chat_scroll from "./plugins/chat-scroll";

Vue.config.productionTip = false

new Vue({
    constants,
    router,
    vuetify,
    axios,
    firebase,
    filters,
    vue_editor,
    vue_moment,
    store,
    chat_scroll,
    data: {
        globals,
        validation_rules,
        translations,
        snackbar,
    },
    render: h => h(App)
}).$mount('#app')
