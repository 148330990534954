const other_routes = {
    path: '/',
    name: 'Other',
    redirect: '/404',
    component: () => import('../../views/Layout.vue'),
    children: [
        {
            path: 'article/:slug',
            name: 'Article',
            component: () => import('../../views/Article.vue')
        },
        {
            path: 'support',
            name: 'Support',
            component: () => import('../../views/Support.vue')
        },
        {
            path: '*',
            name: 'NotFound',
            component: () => import(/* webpackChunkName: "error" */ '../../views/NotFound.vue')
        },
    ]
}

export default other_routes
