import {HTTP} from '@shared/plugins/axios'

export const CustomersCompetenciesModule = {
    namespaced: true,
    state: () => ({
        list: null,
        competencies: null,
        new_list: [],
        deleted_list: [],
    }),
    getters: {
        getList: (state) => () => {
            return state.list
        },
        getCompetencies: (state) => (query) => {
            if (query) {
                return state.competencies.filter(el => el.name.indexOf(query) !== -1);
            }
            return state.competencies;
        },
    },
    mutations: {
        setList: function (state, list) {
            state.list = list;
            state.new_list = [];
            state.deleted_list = [];
        },
        setNewList: function (state, list) {
            state.new_list = list;
        },
        setCompetencies: function (state, competencies) {
            state.competencies = competencies;
        },
        addItem: function (state, item) {
            state.list.push(item);
            state.new_list.push(item);
        },
        removeItem: function (state, index) {
            state.deleted_list.push(state.list.splice(index, 1)[0]);
        },
    },
    actions: {
        loadList: function ({commit}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .get("customer-competency/get")
                    .then(function (response) {
                        commit("setList", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        loadCompetencies: function ({commit}, query) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("/criteria/competencies", {
                        query: query || ''
                    })
                    .then(function (response) {
                        commit("setCompetencies", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        save: function ({commit, state}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("customer-competency/save", {
                        competencies_new: state.new_list,
                        competencies_deleted: state.deleted_list
                    })
                    .then(function (response) {
                        commit("setList", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
    }
}
