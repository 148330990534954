import {HTTP} from '@shared/plugins/axios'

export const CountriesModule = {
    namespaced: true,
    state: () => ({
        list: [],
    }),
    getters: {
        getAll: (state) => () => {
            return state.list
        },
    },
    mutations: {
        setList: function (state, list) {
            state.list = list;
        },
    },
    actions: {
        prefixes: function ({commit}, query) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("countries/search/prefixes", {
                        query: query || ''
                    })
                    .then(function (response) {
                        commit("setList", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        commit("setList", [])
                        reject(response);
                    })
            })
        },
    }
}
