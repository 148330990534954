import {HTTP} from '@shared/plugins/axios'

export const CriteriaModule = {
    namespaced: true,
    state: () => ({
        professions: [],
        job_categories: [],
        personalities: [],
        competencies: [],
        languages: [],
        radiusTypes: [],
        languageTypes: [],
        countries: [],
        cities: [],
    }),
    getters: {
        getJobCategories: (state) => () => {
            return state.job_categories
        },
        getProfessions: (state) => () => {
            return state.professions
        },
        getPersonalities: (state) => () => {
            return state.personalities
        },
        getCompetencies: (state) => () => {
            return state.competencies
        },
        getLanguages: (state) => () => {
            return state.languages
        },
        getRadiusTypes: (state) => () => {
            return state.radiusTypes
        },
        getLanguageTypes: (state) => () => {
            return state.languageTypes
        },
        getCountries: (state) => () => {
            return state.countries
        },
        getCities: (state) => () => {
            return state.cities
        },
    },
    mutations: {
        setJobCategories: function (state, list) {
            state.job_categories = list;
        },
        setProfessions: function (state, list) {
            state.professions = list;
        },
        setPersonalities: function (state, list) {
            state.personalities = list;
        },
        setCompetencies: function (state, list) {
            state.competencies = list;
        },
        setLanguages: function (state, list) {
            state.languages = list;
        },
        setRadiusTypes: function (state, list) {
            state.radiusTypes = list;
        },
        setLanguageTypes: function (state, list) {
            state.languageTypes = list;
        },
        setCountries: function (state, list) {
            state.countries = list;
        },
        setCities: function (state, list) {
            state.cities = list;
        },
    },
    actions: {
        loadAll: function ({dispatch}) {
            return Promise.all([
                dispatch('loadStatic'),
                dispatch('loadProfessions'),
                dispatch('loadPersonalities'),
                dispatch('loadCompetencies'),
                dispatch('loadLanguages'),
                dispatch('loadCountries'),
            ])
        },
        loadCategories: function ({commit}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("criteria/categories")
                    .then(function (response) {
                        commit("setJobCategories", response.data)
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        loadProfessions: function ({commit}, search) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("criteria/professions", search)
                    .then(function (response) {
                        commit("setProfessions", response.data)
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        loadPersonalities: function ({commit}, query) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("criteria/personalities", {
                        query: query || ''
                    })
                    .then(function (response) {
                        commit("setPersonalities", response.data)
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        loadCompetencies: function ({commit}, query) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("criteria/competencies", {
                        query: query || ''
                    })
                    .then(function (response) {
                        commit("setCompetencies", response.data)
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        loadLanguages: function ({commit}, query) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("criteria/languages", {
                        query: query || ''
                    })
                    .then(function (response) {
                        commit("setLanguages", response.data)
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        loadCountries: function ({commit}, query) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("countries/search/countries", {
                        query: query || ''
                    })
                    .then(function (response) {
                        commit("setCountries", response.data)
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        loadCities: function ({commit}, search) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("countries/search/cities", search)
                    .then(function (response) {
                        commit("setCities", response.data)
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        loadRadiusTypes: function ({commit}, search) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("criteria/radius-types")
                    .then(function (response) {
                        commit("setRadiusTypes", response.data)
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        loadStatic: function ({commit}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .get("criteria/all")
                    .then(function (response) {
                        commit("setJobCategories", response.data.categories)
                        commit("setLanguageTypes", response.data.language_types)
                        commit("setRadiusTypes", response.data.radius_types)
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        }
    }
}
