export default {
    ACCOUNT_TYPE_CANDIDATE: 'candidate',
    ACCOUNT_TYPE_BOSS: 'boss',
    STATUS_ACTIVE: "3bffc9a2-cb34-40cc-8249-d54ae0a50467",
    STATUS_INACTIVE: "d372eb39-6820-47d1-99ee-11c091d29ffb",
    STATUS_ACCEPTED: "19dfb938-4568-4fe4-88a4-d192f4d939bb",
    STATUS_DECLINED: "2abbcc0a-62b7-4056-b097-505190926398",
    FIREBASE_CONFIG: {
        apiKey: "AIzaSyBQbEZ4N0NWCB55kHEKX5xoVlwGyn5znRk",
        authDomain: "velju-297411.firebaseapp.com",
        projectId: "velju-297411",
        storageBucket: "velju-297411.appspot.com",
        messagingSenderId: "435024629272",
        appId: "1:435024629272:web:f789eb6741169cd8a57d6d",
        measurementId: "G-ZRPQ638WC3"
    },
    FCM_REQUEST_SEND: "customer_request_send",
    FCM_REQUEST_ACCEPTED: "customer_request_accepted",
    FCM_REQUEST_DECLINED: "customer_request_declined",
    FCM_CHAT_UPDATE: "customer_chat_update",
    FCM_TEXT_MESSAGE: "customer_text_message",
    FCM_CREDITS_UPDATE: "customer_credits_update",
    FCM_REQUEST_UPDATE: "customer_request_update",
}
