import {HTTP} from '@shared/plugins/axios'

export const CustomersLanguagesModule = {
    namespaced: true,
    state: () => ({
        list: null,
        types: null,
        languages: null,
        new_list: [],
        deleted_list: [],
    }),
    getters: {
        getList: (state) => () => {
            return state.list
        },
        getTypes: (state) => () => {
            return state.types
        },
        getLanguages: (state) => () => {
            return state.languages
        },
    },
    mutations: {
        setList: function (state, list) {
            state.list = list;
            state.new_list = [];
            state.deleted_list = [];
        },
        setNewList: function (state, list) {
            state.new_list = list;
        },
        setTypes: function (state, types) {
            state.types = types;
        },
        setLanguages: function (state, languages) {
            state.languages = languages;
        },
        addItem: function (state, item) {
            state.list.push(item);
            state.new_list.push(item);
        },
        removeItem: function (state, index) {
            state.deleted_list.push(state.list.splice(index, 1)[0]);
        },
    },
    actions: {
        loadList: function ({commit}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .get("customer-language/get")
                    .then(function (response) {
                        commit("setList", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        loadTypes: function ({commit}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .get("customer-language/types")
                    .then(function (response) {
                        commit("setTypes", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        loadLanguages: function ({commit}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("/criteria/languages", {
                        query: '',
                    })
                    .then(function (response) {
                        commit("setLanguages", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        save: function ({commit, state}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("customer-language/save", {
                        languages_new: state.new_list,
                        languages_deleted: state.deleted_list
                    })
                    .then(function (response) {
                        commit("setList", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
    }
}
