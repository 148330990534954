import store from '@app/plugins/store';

const app_routes = {
    path: '/',
    name: 'App',
    redirect: () => {
        if (store.getters['temp/getAccountType']() === 'candidate') {
            return {name: 'Dashboard'};
        }
        if (store.getters['temp/getAccountType']() === 'boss') {
            return {name: 'Matches'};
        }
        return {name: 'Login'};
    },
    component: () => import('../../views/app/Layout.vue'),
    children: [
        {
            path: 'register-profile',
            name: 'RegisterSeekerProfile',
            component: () => import('../../views/app/RegisterSeekerProfile.vue')
        },
        {
            path: 'dashboard',
            name: 'Dashboard',
            component: () => import('../../views/app/Dashboard.vue')
        },
        {
            path: 'matches',
            name: 'Matches',
            component: () => import('../../views/app/matches/Matches.vue')
        },
        {
            path: 'match/send',
            name: 'SendRequest',
            component: () => import('../../views/app/matches/SendRequest.vue')
        },
        {
            path: 'match/profile',
            name: 'MatchProfile',
            component: () => import('../../views/app/matches/MatchProfile.vue')
        },
        {
            path: 'requests/:type?',
            name: 'Requests',
            component: () => import('../../views/app/requests/Requests.vue')
        },
        {
            path: 'request-profile/:id',
            name: 'RequestProfile',
            component: () => import('../../views/app/requests/RequestProfile.vue')
        },
        {
            path: 'messages',
            name: 'Messages',
            component: () => import('../../views/app/messages/Messages.vue')
        },
        {
            path: 'messages/:id',
            name: 'Chat',
            component: () => import('../../views/app/messages/Chat.vue')
        },
        {
            path: 'notifications',
            name: 'Notifications',
            component: () => import('../../views/app/Notifications.vue')
        },
        {
            path: 'profile',
            name: 'SeekerProfile',
            component: () => import('../../views/app/seeker-profile/SeekerProfile.vue')
        },
        {
            path: 'education',
            name: 'SeekerProfileEducation',
            component: () => import('../../views/app/seeker-profile/Education.vue')
        },
        {
            path: 'experience',
            name: 'SeekerProfileExperience',
            component: () => import('../../views/app/seeker-profile/Experience.vue')
        },
        {
            path: 'languages',
            name: 'SeekerProfileLanguages',
            component: () => import('../../views/app/seeker-profile/Languages.vue')
        },
        {
            path: 'personalities',
            name: 'SeekerPersonalitiesLanguages',
            component: () => import('../../views/app/seeker-profile/Personalities.vue')
        },
        {
            path: 'competencies',
            name: 'SeekerCompetenciesLanguages',
            component: () => import('../../views/app/seeker-profile/Competencies.vue')
        },
        {
            path: 'availability',
            name: 'SeekerAvailability',
            component: () => import('../../views/app/seeker-profile/Availability.vue')
        },
        {
            path: 'credits',
            name: 'Credits',
            component: () => import('../../views/app/Credits.vue')
        },
        {
            path: 'settings',
            name: 'Settings',
            component: () => import('../../views/app/settings/Settings.vue')
        },
        {
            path: 'settings/notifications',
            name: 'SettingsNotifications',
            component: () => import('../../views/app/settings/SettingsNotifications.vue')
        },
        {
            path: 'settings/profile',
            name: 'SettingsProfile',
            component: () => import('../../views/app/settings/SettingsProfile.vue')
        },
        {
            path: 'faq',
            name: 'FAQ',
            component: () => import('../../views/app/FAQ.vue')
        },
        {
            path: 'support-request',
            name: 'SupportRequest',
            component: () => import('../../views/app/Support.vue')
        },
    ],
}

export default app_routes
