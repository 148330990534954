import {HTTP} from '@shared/plugins/axios'

export const SupportRequestsModule = {
    namespaced: true,
    state: () => ({
        list: [],
        selected: null,
        total: 0,
    }),
    getters: {
        getTotal: (state) => () => {
            return state.total
        },
        getAll: (state) => () => {
            return state.list
        },
        getSelected: (state) => () => {
            return state.selected
        }
    },
    mutations: {
        save: function (state, item) {
            state.list.push(item);
        },
        update: function (state, item) {
            let index = state.list.findIndex(el => el.id == item.id)
            if (index !== -1) {
                state.list.splice(index, 1, item);
            }
        },
        delete: function (state, item) {
            let index = state.list.findIndex(el => el.id == item.id)
            if (index !== -1) {
                state.list.splice(index, 1);
                state.total -= 1;
            }
        },
        setList: function (state, list) {
            state.list = list;
        },
        setTotal: function (state, total) {
            state.total = total;
        },
        setSelected: function (state, selected) {
            state.selected = selected;
        },
    },
    actions: {
        send: function (_, item) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("support/send", item)
                    .then(function () {
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        question: function (_, item) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("support/question", item)
                    .then(function () {
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        done: function ({commit}, item) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("support/done", item)
                    .then(function (response) {
                        commit("update", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        delete: function ({commit}, item) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("support/delete", item)
                    .then(function (response) {
                        commit("delete", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        filter: function ({commit}, data) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("support/filter", data)
                    .then(function (response) {
                        commit("setList", response.data.items)
                        commit("setTotal", response.data.total)
                        resolve();
                    })
                    .catch(function (response) {
                        commit("setList", [])
                        commit("setTotal", 0)
                        reject(response);
                    })
            })
        },
        loadAll: function ({commit}, data) {
            return new Promise((resolve, reject) => {
                HTTP
                    .get("support/types", data)
                    .then(function (response) {
                        commit("setList", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        commit("setList", [])
                        reject(response);
                    })
            })
        },
        loadById: function ({commit}, id) {
            commit("setSelected", null)
            return new Promise((resolve, reject) => {
                if (id) {
                    HTTP
                        .get("support/get/" + id)
                        .then(function (response) {
                            commit("setSelected", response.data)
                            resolve();
                        })
                        .catch(function (response) {
                            reject(response);
                        })
                } else {
                    reject()
                }

            })
        },
    }
}
