import Vue from 'vue'
import Vuex from 'vuex'
import auth from "@shared/store/module/auth";
import {TranslationsModule} from "./module/translations";
import {UsersModule} from "./module/users";
import {LanguagesModule} from "./module/languages";
import {RolesModule} from "./module/roles";
import {CompetenciesModule} from "./module/competencies";
import {PersonalitiesModule} from "./module/personalities";
import {ProfessionsModule} from "./module/professions";
import {JobCategoriesModule} from "./module/job_categories";
import {CustomersModule} from "./module/customers";
import {SupportRequestsModule} from "./module/support_requests";
import {ArticlesModule} from "./module/articles";
import {NotificationsModule} from "./module/notifications";
import {FaqModule} from "./module/faq";
import {CustomersLanguagesModule} from "./module/customers_languages";
import {CustomersPersonalitiesModule} from "./module/customers_personalities";
import {CustomersInfoModule} from "./module/customers_info";
import {CustomersEducationModule} from "./module/customers_education";
import {CustomersExperienceModule} from "./module/customers_experience";
import {RequestsModule} from "./module/requests";
import {CountriesModule} from "./module/countries";
import {CustomersCompetenciesModule} from "./module/customers_competencies";
import {EmailVerificationModule} from "./module/email_verification";
import {CriteriaModule} from "./module/criteria";
import {MatchesModule} from "./module/matches";
import {PhoneVerificationModule} from "./module/phone_verification";
import {ChatsModule} from "./module/chats";
import {PendingProfessionsModule} from "./module/pending_professions";
import {CreditsModule} from "./module/credits";
import {PaymentsModule} from "./module/payments";
import {CustomersAvailabilityModule} from './module/customers_availability';
import temp from '@shared/store/module/temp';
import {SettingsModule} from '@app/plugins/store/module/settings';
import {LayoutModule} from '@app/plugins/store/module/layout';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        temp,
        translations: TranslationsModule,
        users: UsersModule,
        languages: LanguagesModule,
        roles: RolesModule,
        competencies: CompetenciesModule,
        personalities: PersonalitiesModule,
        professions: ProfessionsModule,
        pending_professions: PendingProfessionsModule,
        job_categories: JobCategoriesModule,
        customers: CustomersModule,
        customers_info: CustomersInfoModule,
        customers_availability: CustomersAvailabilityModule,
        customers_education: CustomersEducationModule,
        customers_experience: CustomersExperienceModule,
        customers_languages: CustomersLanguagesModule,
        customers_personalities: CustomersPersonalitiesModule,
        customers_competencies: CustomersCompetenciesModule,
        support_requests: SupportRequestsModule,
        articles: ArticlesModule,
        notifications: NotificationsModule,
        faq: FaqModule,
        requests: RequestsModule,
        countries: CountriesModule,
        email_verification: EmailVerificationModule,
        phone_verification: PhoneVerificationModule,
        criteria: CriteriaModule,
        matches: MatchesModule,
        chats: ChatsModule,
        credits: CreditsModule,
        payments: PaymentsModule,
        settings: SettingsModule,
        layout: LayoutModule,
    }
})
