import {HTTP} from '@shared/plugins/axios'

export const LayoutModule = {
    namespaced: true,
    state: () => ({
        messages_unread: 0,
        requests_active: 0,
    }),
    getters: {
        getMessagesUnread: (state) => () => {
            return state.messages_unread
        },
        getRequestsActive: (state) => () => {
            return state.requests_active
        },
    },
    mutations: {
        setMessagesUnread: function (state, messages_unread) {
            state.messages_unread = messages_unread;
        },
        setRequestsActive: function (state, requests_active) {
            state.requests_active = requests_active;
        },
    },
    actions: {
        loadMessagesUnread: function ({commit}, item) {
            return new Promise((resolve, reject) => {
                HTTP
                    .get("chat/unread")
                    .then(function (response) {
                        commit("setMessagesUnread", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        loadRequestsActive: function ({commit}, item) {
            return new Promise((resolve, reject) => {
                HTTP
                    .get("request/active_count")
                    .then(function (response) {
                        commit("setRequestsActive", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
    }
}
