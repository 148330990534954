import Vue from "vue";
import {HTTP} from "./axios";

const translations = {
    loaded: {},
    fetch: function (locale) {
        return new Promise((resolve, reject) => {
            HTTP
                .get("translations/all")
                .then((response) => {
                    this.load(response.data);
                    resolve(response.data);
                })
                .catch(function (response) {
                    reject(response);
                })
        })
    },
    load: function (items) {
        const keys = Object.keys(items);
        for (let key of keys) {
            Vue.set(this.loaded, key, items[key]);
        }
    },
    translate: function (key, ...args) {
        if (key && typeof key === 'string') {
            const newKey = key.toLowerCase()
                .replaceAll(' ', '_')
                .replaceAll('\'', '')
                .replaceAll('"', '')
                .replaceAll(',', '')
                .replaceAll('.', '')
                .replaceAll(';', '')
            let content = this.loaded[newKey]
            if (content && args && args.length > 0) {
                args.forEach((el, i) => {
                    content = content.replaceAll(`\${${i + 1}}`, el)
                })
            }
            return content || key;
        }
        return key;
    },
    translateAll: function (key, ...args) {
        if (key && typeof key === 'string') {
            const newKey = key.toLowerCase()
                .replaceAll(' ', '_')
                .replaceAll('\'', '')
                .replaceAll('"', '')
                .replaceAll(',', '')
                .replaceAll('.', '')
                .replaceAll(';', '')
            let content = this.loaded[newKey]
            if (content && args && args.length > 0) {
                args.forEach((el, i) => {
                    content = content.replaceAll(`\${${i + 1}}`, this.translate(el))
                })
            }
            return content || key;
        }
        return key;
    }
};

Vue.use({
    install: function () {
        Object.defineProperty(Vue.prototype, '$translations', {
            get() {
                return translations
            }
        })
    }
});


export default translations;
