import {HTTP} from '@shared/plugins/axios'

export const LanguagesModule = {
    namespaced: true,
    state: () => ({
        list: [],
        total: 0,
    }),
    getters: {
        getTotal: (state) => () => {
            return state.total
        },
        getAll: (state) => () => {
            return state.list
        }
    },
    mutations: {
        update: function (state, language) {
            let index = state.list.findIndex(item => item.id == language.id)
            if (index !== -1) {
                state.list.splice(index, 1, language);
            }
        },
        delete: function (state, language) {
            let index = state.list.findIndex(item => item.id == language.id)
            if (index !== -1) {
                state.list.splice(index, 1);
                state.total -= 1;
            }
        },
        setList: function (state, list) {
            state.list = list;
        },
        setTotal: function (state, total) {
            state.total = total;
        },
    },
    actions: {
        loadActive: function ({commit}) {
            commit("setList", [])
            return new Promise((resolve, reject) => {
                HTTP
                    .get("languages/active")
                    .then(function (response) {
                        commit("setList", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        loadTranslated: function ({commit}) {
            commit("setList", [])
            return new Promise((resolve, reject) => {
                HTTP
                    .get("languages/translated")
                    .then(function (response) {
                        commit("setList", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        loadSpoken: function ({commit}) {
            commit("setList", [])
            return new Promise((resolve, reject) => {
                HTTP
                    .get("languages/spoken")
                    .then(function (response) {
                        commit("setList", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        delete: function ({commit}, item) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("languages/delete", {
                        id: item.id
                    })
                    .then(function () {
                        commit("delete", item)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        update: function ({commit}, item) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("languages/update", item)
                    .then(function (response) {
                        commit("update", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        filter: function ({commit}, data) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("languages/filter", data)
                    .then(function (response) {
                        commit("setList", response.data.items)
                        commit("setTotal", response.data.total)
                        resolve();
                    })
                    .catch(function (response) {
                        commit("setList", [])
                        commit("setTotal", 0)
                        reject(response);
                    })
            })
        },
    }
}
