import Vue from "vue";
import translations from "./translations";
import moment from "moment";

const rules = {
    noop: () => {
        return () => true;
    },
    required: (label) => {
        return v => !!v || translations.translateAll('validation_error_required', label);
    },
    email: (label) => {
        return v => !v || (!!v && /.+@.+\..+/.test(v)) || translations.translateAll('validation_error_email', label);
    },
    number: (label) => {
        return v => !v || (!!v && /^\d+$/.test(v)) || translations.translateAll('validation_error_number', label);
    },
    decimal: (label) => {
        return v => !v || (!!v && /^\d+(\.\d{1,2})?$/.test(v)) || translations.translateAll('validation_error_decimal', label);
    },
    min: (label, value) => {
        return v => !v || (!!v && v >= value) || translations.translateAll('validation_error_min', label, value);
    },
    max: (label, value) => {
        return v => !v || (!!v && v <= value) || translations.translateAll('validation_error_max', label, value);
    },
    minLength: (label, length) => {
        return v => !v || (!!v && v.length >= length) || translations.translateAll('validation_error_min_length', label, length);
    },
    maxLength: (label, length) => {
        return v => !v || (!!v && v.length <= length) || translations.translateAll('validation_error_max_length', label, length);
    },
    exactLength: (label, length) => {
        return v => !v || (!!v && v.length <= length) || translations.translateAll('validation_error_exact_length', label, length);
    },
    match: (label, label2, value) => {
        return v => !v || (!!v && v === value) || translations.translateAll('validation_error_match', label, label2);
    },
    adult: () => {
        return v => (!!v && moment(v, 'DD.MM.YYYY').isBefore(moment().subtract(18, 'year'))) || translations.translate('validation_error_adult');
    },
}

const validations = {
    rules: function (label, validations) {
        return validations
            .map(el => {
                if (typeof el === 'function') {
                    const result = el();
                    const validators = Object.keys(result);
                    return validators.map(el2 => {
                        const validator = rules[el2];
                        if (validator) {
                            return validator(label, ...result[el2]);
                        }
                        return rules.noop()
                    })
                } else {
                    const splits = el.split(":");
                    if (splits.length > 0) {
                        const validator = rules[splits[0]];
                        if (validator) {
                            return validator(label, ...splits.slice(1));
                        }
                    }
                }
                return rules.noop()
            })
            .flat()
    },
}

Vue.use({
    install: function () {
        Object.defineProperty(Vue.prototype, '$validations', {
            get() {
                return validations
            }
        })
    }
});

export default validations;
