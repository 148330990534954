import Vue from 'vue';
import Vuetify from 'vuetify';
import MaterialIcon from "@shared/components/MaterialIcon";

Vue.use(Vuetify);

function missingMaterialIcons(ids) {
    const icons = {}
    for (const id of ids) {
        for (const suffix of ['fill', 'outline']) {
            const name = `${id}_${suffix}`
            icons[name] = {
                component: MaterialIcon,
                props: {
                    name
                }
            }
        }
    }
    return icons
}

const vuetify = new Vuetify({
    icons: {
        iconfont: 'md',
        values: {
            ...missingMaterialIcons([
                'monetization_on',
                'home',
                'school',
                'star',
                'schedule',
                'language',
                'work',
                'face',
                'settings',
                'info',
                'help',
                'dashboard',
                'contact_page',
                'chat',
                'notifications',
                'gavel',
                'check_circle',
                'highlight_off',
                'place',
                'flag',
                'apartment',
                'thumb_up',
                'thumb_down',
                'policy',
                'add_a_photo',
                'person',
                'mail',
                'phone',
                'verified',
                'delete_forever',
                'pending'
            ]),
            // complete: '$complete',
            // cancel: '$cancel',
            // close: '$close',
            // delete: '$delete',
            // clear: '$clear',
            // success: '$success',
            // info: '$info',
            // warning: '$warning',
            // error: '$error',
            // prev: '$navigate_before',
            // next: '$navigate_next',
            // checkboxOn: '$check_box',
            // checkboxOff: '$check_box_outline_blank',
            // checkboxIndeterminate: '$indeterminate_check_box',
            // delimiter: '$remove',
            // sort: '$sort',
            // expand: '$expand_more',
            // menu: '$menu_outline',
            // subgroup: '$expand_more',
            // dropdown: '$expand_more',
            // radioOn: '$radio_button_checked',
            // radioOff: '$radio_button_unchecked',
            // edit: '$edit',
            // ratingEmpty: '$star_outline',
            // ratingFull: '$star',
            // ratingHalf: '$star_half',
            // file: '$description',
        }
    },
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#00a2ff',
                secondary: '#fafafa',
                accent: '#00a2ff',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FB8C00',
            }
        },
        options: {
            customProperties: true,
        },
    }
});

export default vuetify;
