import {HTTP} from '@shared/plugins/axios'

export const CustomersInfoModule = {
    namespaced: true,
    state: () => ({
        info: null,
    }),
    getters: {
        getInfo: (state) => () => {
            return state.info
        },
    },
    mutations: {
        setInfo: function (state, info) {
            state.info = info;
        },
    },
    actions: {
        load: function ({commit}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .get("customer/info")
                    .then(function (response) {
                        commit("setInfo", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        register: function ({commit}, data) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("customer/register", data)
                    .then(function (response) {
                        commit('auth/setHasInfo', null, {root: true})
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        updateStatus: function ({dispatch}, value) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("customer/update/status", {
                        is_available: value
                    })
                    .then(function (response) {
                        dispatch('load');
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        updateAvailability: function ({dispatch}, value) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("customer/update/availability", value)
                    .then(function (response) {
                        dispatch('load');
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        updateDescription: function ({dispatch}, value) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("customer/update/description", {
                        short_description: value
                    })
                    .then(function (response) {
                        dispatch('load');
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        updateProfession: function ({dispatch}, value) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("customer/update/profession", {
                        profession: value
                    })
                    .then(function (response) {
                        dispatch('load');
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        updateRadiusType: function ({dispatch}, value) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("customer/update/radius", {
                        radius_type: value
                    })
                    .then(function (response) {
                        dispatch('load');
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        updateDate: function ({dispatch}, value) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("customer/update/date", {
                        birth_date: value
                    })
                    .then(function (response) {
                        dispatch('load');
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        updateResidence: function ({dispatch}, value) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("customer/update/residence", value)
                    .then(function (response) {
                        dispatch('load');
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        setPendingProfession: function ({dispatch}, value) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("professions/request", {
                        name: value,
                    })
                    .then(function (response) {
                        dispatch('updateProfession', response.data);
                        dispatch('load');
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        }
    }
}
