<template>
    <div>
        <router-view/>
        <v-snackbar
            v-model="$snackbar.visible"
            style="position: fixed"
            bottom
            right
        >
            {{ $snackbar.text }}
        </v-snackbar>
        <preloader
            v-if="!loaded"
            :icon="'/assets/logo_primary.svg'"
        />
    </div>
</template>
<script>
import Preloader from "@shared/components/Preloader";

export default {
    name: 'App',
    components: {Preloader},
    data: () => ({
        loaded: false,
    }),
    methods: {
        fetchTranslations() {
            const noTranslate = this.$store.getters['temp/getNoTranslate'] || this.$route.query.no_translate !== undefined;
            if (noTranslate) {
                this.$translations.loaded = {};
                return Promise.resolve();
            } else {
                return this.$translations.fetch(this.$store.getters['temp/getLocale']());
            }
        }
    },
    created() {
        Promise.all([
            this.$store.dispatch('temp/load')
                .then(() => {
                    return this.fetchTranslations();
                }),
            this.$store.dispatch('auth/loadPayments'),
        ]).finally(() => this.loaded = true)
    }
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

html {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: .01rem;

    body {
        font-family: Poppins, Helvetica, Arial, sans-serif;
        font-weight: 400;
        color: #626262;
    }
}

button {
    &.v-btn {
        font-weight: bold !important;
        box-shadow: none !important;

        &.v-btn--fab {

            &.primary, &.accent {
                background: linear-gradient(to bottom, #00A2FF, #007FC9);
            }
        }

        &.v-btn--rounded {

            &.primary, &.accent {
                background: linear-gradient(to bottom, #00A2FF, #007FC9);
            }

            &.secondary {
                color: var(--v-primary-base) !important;
                border: 1px solid #E8E8E8 !important;
                border-color: #E8E8E8 !important;
            }
        }
    }
}

.v-card__title {
    word-break: break-word;
}
</style>
