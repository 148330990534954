import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/messaging";
import Vue from "vue";
import constants from "@shared/constants";
import store from "@app/plugins/store";

firebase.initializeApp(constants.FIREBASE_CONFIG);
const messaging = firebase.messaging.isSupported() ? firebase.messaging() : {};
const analytics = firebase.analytics();

const modules = {
    analytics: {
        logEvent: function (eventName, eventParams) {
            analytics.logEvent(eventName, Object.assign({platform: 'web'}, eventParams))
        }
    },
    messaging: {
        isSupported: function () {
            return firebase.messaging.isSupported();
        },
        getToken: function () {
            if (messaging.getToken) {
                return messaging.getToken({vapidKey: process.env.VUE_APP_FIREBASE_MESSAGING_KEY})
                    .then((currentToken) => {
                        if (currentToken) {
                            return store.dispatch('customers/updateFcm', currentToken)
                        } else {
                            console.log('per')
                        }
                    })
                    .catch((err) => {
                        console.log('An error occurred while retrieving token. ', err);
                    });
            } else {
                return Promise.reject("Platform not supported")
            }
        },
        onMessage: function (callback) {
            if (messaging.onMessage) {
                return messaging.onMessage(function (payload) {
                    if (payload.data) {
                        callback({
                            type: payload.data.type,
                            data: payload.data.data
                        });
                    }
                });
            }
            return;
        },
        onChatUpdate: function (callback) {
            return this.onMessage(function (data) {
                if (data.type === constants.FCM_CHAT_UPDATE) {
                    callback(JSON.parse(data.data));
                }
            });
        }
    }
}

modules.messaging.getToken();

Vue.use({
    install: function () {
        Object.defineProperty(Vue.prototype, '$firebase', {
            get() {
                return modules
            }
        })
    }
});

export default modules;
