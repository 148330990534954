import {HTTP} from '@shared/plugins/axios'
import {orderBy} from "lodash";

export const CreditsModule = {
    namespaced: true,
    state: () => ({
        list: [],
        selected: null,
        total: 0,
    }),
    getters: {
        getTotal: (state) => () => {
            return state.total
        },
        getAll: (state) => () => {
            return state.list
        },
        getSelected: (state) => () => {
            return state.selected
        }
    },
    mutations: {
        save: function (state, item) {
            let index = state.list.findIndex(el => el.id == item.id)
            if (index !== -1) {
                state.list.splice(index, 1, item);
            } else {
                state.list.push(item);
            }
        },
        delete: function (state, item) {
            let index = state.list.findIndex(el => el.id == item.id)
            if (index !== -1) {
                state.list.splice(index, 1);
                state.total -= 1;
            }
        },
        setList: function (state, list) {
            state.list = orderBy(list, ['credits']);
        },
        setTotal: function (state, total) {
            state.total = total;
        },
        setSelected: function (state, selected) {
            state.selected = selected;
        },
    },
    actions: {
        save: function ({commit}, item) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("credits/save", item)
                    .then(function (response) {
                        commit("save", response.data)
                        commit("setSelected", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        delete: function ({commit}, item) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("credits/delete", {
                        id: item.id
                    })
                    .then(function () {
                        commit("delete", item)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        filter: function ({commit}, data) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("credits/filter", data)
                    .then(function (response) {
                        commit("setList", response.data.items)
                        commit("setTotal", response.data.total)
                        resolve();
                    })
                    .catch(function (response) {
                        commit("setList", [])
                        commit("setTotal", 0)
                        reject(response);
                    })
            })
        },
        loadById: function ({commit}, id) {
            return new Promise((resolve, reject) => {
                if (id) {
                    HTTP
                        .get("credits/get/" + id)
                        .then(function (response) {
                            commit("setSelected", response.data)
                            resolve();
                        })
                        .catch(function (response) {
                            reject(response);
                        })
                } else {
                    commit("setSelected", null)
                    resolve();
                }
            })
        },
        loadAvailable: function ({commit}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .get("credits/available")
                    .then(function (response) {
                        commit("setList", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
    }
}
