<template>
    <div class="preloader">
        <v-img
            class="ma-5"
            :src="icon"
            width="100"
            max-width="100"
            max-height="100"
            contain
        />
        <v-progress-linear
            class="ma-5"
            indeterminate
            color="primary"
        />
    </div>
</template>
<style lang="scss" scoped>
    @import '~vuetify/src/styles/styles.sass';

    .preloader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #fff;
        z-index: 100;
    }
</style>
<script>
    export default {
        name: "Preloader",
        props: {
            icon: String
        }
    }
</script>
