const email_routes = {
    path: '/email',
    name: 'Email',
    redirect: {
        name: 'EmailVerification'
    },
    component: () => import('../../views/auth/Layout.vue'),
    children: [
        {
            path: 'verify',
            name: 'EmailVerification',
            component: () => import('../../views/auth/EmailVerification.vue')
        }
    ],
}

export default email_routes
