import {HTTP} from '@shared/plugins/axios'

export const EmailVerificationModule = {
    namespaced: true,
    actions: {
        verify: function ({commit}, data) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("email/verify", data)
                    .then(function (response) {
                        commit('auth/clearChecked', null, {root: true})
                        resolve(response);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        send: function () {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("email/send-token")
                    .then(function (response) {
                        resolve(response);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
    }
}
