import {HTTP} from '@shared/plugins/axios'

export const PaymentsModule = {
    namespaced: true,
    actions: {
        stripeCheckout: function (_, credit) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("payment/stripe/checkout", {
                        credit_id: credit.id,
                    })
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        paypalCreateOrder: function (_, credit) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("payment/paypal/create", {
                        credit_id: credit.id,
                    })
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        paypalCaptureOrder: function (_, orderId) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("payment/paypal/capture", {
                        order_id: orderId,
                    })
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
    }
}
