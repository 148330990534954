import {HTTP} from '@shared/plugins/axios'

export const RequestsModule = {
    namespaced: true,
    state: () => ({
        active: [],
        responded: [],
        selected: null,
        profile: null,
    }),
    getters: {
        getActive: (state) => () => {
            return state.active
        },
        getResponded: (state) => () => {
            return state.responded
        },
        getSelected: (state) => () => {
            return state.selected
        },
        getProfile: (state) => () => {
            return state.profile
        }
    },
    mutations: {
        setActive: function (state, active) {
            state.active = active;
        },
        setResponded: function (state, responded) {
            state.responded = responded;
        },
        setProfile: function (state, profile) {
            state.profile = profile;
        },
    },
    actions: {
        loadActive: function ({commit}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .get("request/active")
                    .then(function (response) {
                        commit("setActive", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        loadResponded: function ({commit}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .get("request/responded")
                    .then(function (response) {
                        commit("setResponded", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        loadAll: function ({dispatch}) {
            return Promise.all([
                dispatch('loadActive'),
                dispatch('loadResponded'),
            ])
        },
        loadProfile: function ({commit}, id) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("request/profile", {id})
                    .then(function (response) {
                        commit("setProfile", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        respond: function ({commit}, status) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("request/respond", status)
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
    }
}
