import {HTTP} from '@shared/plugins/axios'
import _ from 'lodash';

export const CustomersAvailabilityModule = {
    namespaced: true,
    state: () => ({
        availability: null,
        types: null,
    }),
    getters: {
        getAvailability: (state) => () => {
            return state.availability;
        },
        getTypes: (state) => () => {
            return state.types;
        },
    },
    mutations: {
        setAvailability: function (state, availability) {
            state.availability = Object.assign({}, availability, {
                time_from: availability.time_from && _.lastIndexOf(availability.time_from, ":") === 5 ? availability.time_from.substring(0, 5) : availability.time_from,
                time_to: availability.time_to && _.lastIndexOf(availability.time_to, ":") === 5 ? availability.time_to.substring(0, 5) : availability.time_to,
            });
        },
        setTypes: function (state, types) {
            state.types = types;
        },
    },
    actions: {
        load: function ({commit}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .get("customer-availability/get")
                    .then(function (response) {
                        commit("setAvailability", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        loadTypes: function ({commit}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .get("customer-availability/types")
                    .then(function (response) {
                        commit("setTypes", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        save: function ({commit, state}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("customer-availability/save", state.availability)
                    .then(function (response) {
                        commit("setAvailability", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
    }
}
