import Vue from "vue";

let globals = {
    user: null,
    loading: false,
    limit: 5,
    formatUrl: function (link) {
        if (link) {
            return `${process.env.MIX_BASE_URL}/${link}`
        }
        return null;
    },
    extractInitials: function (fullName) {
        var matches = fullName.matchAll(new RegExp(
            '(?:^|\\s|-)+([^\\s-])[^\\s-]*(?:(?:\\s+)(?:the\\s+)?(?:jr|sr|II|2nd|III|3rd|IV|4th)\\.?$)?', 'i'));
        console.log(matches);
        var result = "";
        var count = 0;
        for (var match in matches) {
            count++;
            result += match.group(1);
            if (count >= 3) {
                break;
            }
        }
        return result.toUpperCase();
    }
};

export function setUser(user) {
    globals.user = user;
}

export function setLoading(loading) {
    globals.loading = loading;
}

Vue.use({
    install: function () {
        Object.defineProperty(Vue.prototype, '$globals', {
            get() {
                return globals
            }
        })
    }
});


export default globals;
