import axios from '@shared/plugins/axios'
import moment from "moment";

const auth_base = `${window.API_URL}/auth`;
const client_id = process.env.MIX_CLIENT_ID;
const client_secret = process.env.MIX_CLIENT_SECRET;

export default {
    namespaced: true,
    state: () => ({
        user: null,
        last_check: null,
        stripe_key: null,
        paypal_key: null,
    }),
    getters: {
        getUser: (state) => () => {
            return state.user
        },
        shouldCheck: (state) => () => {
            return !state.last_check || moment(state.last_check).add(10, 'minutes').isBefore(moment().utc())
        },
        getStripeKey: (state) => () => {
            return state.stripe_key;
        },
        getPaypalKey: (state) => () => {
            return state.paypal_key;
        },
    },
    mutations: {
        setUser: function (state, user) {
            state.user = user;
        },
        setChecked: function (state) {
            state.last_check = moment().utc();
        },
        clearChecked: function (state) {
            state.last_check = null;
        },
        updateSettings: function (state, settings) {
            state.user.settings = settings;
        },
        setPhoneVerified: function (state) {
            state.user.phone_verified_at = moment().utc().format();
            state.user.phone_verified = true;
        },
        setStripeKey: function (state, key) {
            state.stripe_key = key;
        },
        setPaypalKey: function (state, key) {
            state.paypal_key = key;
        },
        setHasInfo: function (state) {
            if (state.user) {
                state.user.has_info = true;
            }
        },
        logout: function (state) {
            state.user = null;
            state.last_check = null;
        },
    },
    actions: {
        login: function ({dispatch}, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("login", Object.assign({client_id, client_secret}, data), {
                        baseURL: auth_base,
                    })
                    .then(function (response) {
                        dispatch('temp/updateToken', response.data.access_token, {root: true});
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        otl: function ({dispatch}, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("verify-otl", data, {
                        baseURL: auth_base,
                    })
                    .then(function (response) {
                        dispatch('temp/updateToken', response.data.access_token, {root: true});
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        register: function (_, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("register", data, {
                        baseURL: auth_base,
                    })
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        logout: function ({dispatch, commit}, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("logout", data, {
                        baseURL: auth_base,
                    })
                    .then(function (response) {
                        dispatch('temp/delete', null, {root: true});
                        commit('auth/logout', null, {root: true});
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        forgot: function (_, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("forgot-password", data, {
                        baseURL: auth_base,
                    })
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        reset: function (_, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("reset-password", data, {
                        baseURL: auth_base,
                    })
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        check: function ({commit, getters}, data) {
            return new Promise((resolve, reject) => {
                if (getters['shouldCheck']()) {
                    axios
                        .post("check", data, {
                            baseURL: auth_base,
                        })
                        .then(function (response) {
                            commit('setUser', response.data)
                            commit('setChecked')
                            resolve(response.data);
                        })
                        .catch(function (response) {
                            reject(response);
                        })
                } else {
                    resolve(getters['getUser']());
                }
            })
        },
        loadPayments: function ({commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .get("payments", {
                        baseURL: auth_base,
                    })
                    .then(function (response) {
                        commit('setStripeKey', response.data.stripe)
                        commit('setPaypalKey', response.data.paypal)
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
    }
}
