import Vue from 'vue';
import {v4 as uuidv4} from 'uuid';

const path = (window.APP_PATH ?? '') + '/';

export default {
    namespaced: true,
    state: () => ({
        token: null,
        locale: 'en',
        type: null,
        criteria: {},
        deviceId: null,
        notifications: null,
        no_translate: false,
        loading: [],
    }),
    getters: {
        getToken: (state) => () => {
            return state.token
        },
        getDeviceId: (state) => () => {
            return state.deviceId
        },
        getLocale: (state) => () => {
            return state.locale
        },
        getAccountType: (state) => () => {
            return state.type
        },
        getCriteria: (state) => () => {
            return state.criteria
        },
        getNotifications: (state) => () => {
            return state.notifications
        },
        getNoTranslate(state) {
            return state.no_translate
        },
        isLoading(state) {
            return state.loading.length > 0;
        },
    },
    mutations: {
        setToken: function (state, token) {
            state.token = token;
        },
        setLocale: function (state, locale) {
            state.locale = locale || 'en';
        },
        setAccountType: function (state, type) {
            state.type = type;
        },
        setCriteria: function (state, criteria) {
            state.criteria = criteria;
        },
        setDeviceId: function (state, deviceId) {
            state.deviceId = deviceId;
        },
        setNotifications: function (state, notifications) {
            state.notifications = notifications;
        },
        setNoTranslate: function (state, no_translate) {
            state.no_translate = no_translate;
        },
        setLoading: function (state, loading) {
            const index = state.loading.indexOf('general');
            if (loading && index === -1) {
                state.loading.push('general');
            } else if (!loading) {
                state.loading.splice(index, 1);
            }
        },
        toggleLoading: function (state, part) {
            const index = state.loading.indexOf(part);
            if (index === -1) {
                state.loading.push(part);
            } else {
                state.loading.splice(index, 1);
            }
        },
        pushLoading: function (state, part) {
            const index = state.loading.indexOf(part);
            if (index === -1) {
                state.loading.push(part);
            }
        },
        popLoading: function (state, part) {
            const index = state.loading.indexOf(part);
            if (index !== -1) {
                state.loading.splice(index, 1);
            }
        },
        deleteCriteria: function (state, key) {
            if (key instanceof Array && key.length > 0) {
                Vue.delete(state.criteria[key[0]], key[1]);
            } else {
                Vue.delete(state.criteria, key);
            }
        },
    },
    actions: {
        load: function ({commit}) {
            return new Promise((resolve) => {
                commit("setToken", localStorage.getItem(`${path}TOKEN`))
                commit("setNoTranslate", localStorage.getItem(`${path}NO_TRANSLATE`) === 'true')
                commit("setLocale", localStorage.getItem(`${path}LOCALE`))
                commit("setAccountType", localStorage.getItem(`${path}ACCOUNT_TYPE`))
                commit("setNotifications", localStorage.getItem(`${path}NOTIFICATIONS`) !== 'false');
                let criteria = localStorage.getItem(`${path}CRITERIA`);
                if (criteria) {
                    commit("setCriteria", JSON.parse(criteria))
                }
                let deviceId = localStorage.getItem(`${path}DEVICE_ID`);
                if (!deviceId) {
                    deviceId = uuidv4();
                    localStorage.setItem(`${path}DEVICE_ID`, deviceId);
                }
                commit("setDeviceId", deviceId);
                resolve();
            })
        },
        delete: function ({commit}) {
            return new Promise((resolve) => {
                localStorage.removeItem(`${path}TOKEN`);
                localStorage.removeItem(`${path}NO_TRANSLATE`);
                localStorage.removeItem(`${path}LOCALE`);
                localStorage.removeItem(`${path}ACCOUNT_TYPE`);
                localStorage.removeItem(`${path}NOTIFICATIONS`);
                localStorage.removeItem(`${path}CRITERIA`);
                localStorage.removeItem(`${path}DEVICE_ID`);
                commit("setToken", null);
                commit("setNoTranslate", false)
                commit("setLocale", 'en')
                commit("setAccountType", null)
                commit("setNotifications", null);
                commit("setCriteria", {})
                commit("setDeviceId", null);
                resolve();
            })
        },
        updateToken: function ({commit}, token) {
            localStorage.setItem(`${path}TOKEN`, token)
            commit("setToken", token)
        },
        updateLocale: function ({commit}, locale) {
            localStorage.setItem(`${path}LOCALE`, locale)
            commit("setLocale", locale)
        },
        updateAccountType: function ({commit}, type) {
            localStorage.setItem(`${path}ACCOUNT_TYPE`, type)
            commit("setAccountType", type)
        },
        updateCriteria: function ({commit}, criteria) {
            localStorage.setItem(`${path}CRITERIA`, JSON.stringify(criteria))
            commit("setCriteria", criteria)
        },
        updateNotifications: function ({commit}, notifications) {
            localStorage.setItem(`${path}NOTIFICATIONS`, JSON.stringify(notifications))
            commit("setNotifications", notifications)
        },
        updateNoTranslate: function ({commit}, no_translate) {
            localStorage.setItem(`${path}NO_TRANSLATE`, no_translate)
            commit("setNoTranslate", no_translate)
        }
    }
}
