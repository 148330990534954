import {HTTP} from '@shared/plugins/axios'

export const CustomersModule = {
    namespaced: true,
    state: () => ({
        list: [],
        selected: null,
        total: 0,
    }),
    getters: {
        getTotal: (state) => () => {
            return state.total
        },
        getAll: (state) => () => {
            return state.list
        },
        getSelected: (state) => () => {
            return state.selected
        }
    },
    mutations: {
        save: function (state, item) {
            state.list.push(item);
        },
        update: function (state, item) {
            let index = state.list.findIndex(el => el.id == item.id)
            if (index !== -1) {
                state.list.splice(index, 1, item);
            }
        },
        delete: function (state, item) {
            let index = state.list.findIndex(el => el.id == item.id)
            if (index !== -1) {
                state.list.splice(index, 1);
                state.total -= 1;
            }
        },
        setList: function (state, list) {
            state.list = list;
        },
        setTotal: function (state, total) {
            state.total = total;
        },
        setSelected: function (state, selected) {
            state.selected = selected;
        },
    },
    actions: {
        filter: function ({commit}, data) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("customers/filter", data)
                    .then(function (response) {
                        commit("setList", response.data.items)
                        commit("setTotal", response.data.total)
                        resolve();
                    })
                    .catch(function (response) {
                        commit("setList", [])
                        commit("setTotal", 0)
                        reject(response);
                    })
            })
        },
        loadAll: function ({commit}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .get("customers/all")
                    .then(function (response) {
                        commit("setList", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        commit("setList", [])
                        reject(response);
                    })
            })
        },
        loadById: function ({commit}, id) {
            commit("setSelected", null)
            return new Promise((resolve, reject) => {
                if (id) {
                    HTTP
                        .get("customers/get/" + id)
                        .then(function (response) {
                            commit("setSelected", response.data)
                            resolve();
                        })
                        .catch(function (response) {
                            reject(response);
                        })
                } else {
                    reject()
                }

            })
        },
        updateSettings: function ({commit}, settings) {
            return new Promise((resolve, reject) => {
                if (settings) {
                    HTTP
                        .post("customer/update/settings", settings)
                        .then(function (response) {
                            commit("auth/updateSettings", response.data, {root: true})
                            resolve(response.data);
                        })
                        .catch(function (response) {
                            reject(response);
                        })
                } else {
                    reject()
                }

            })
        },
        updateName: function ({commit}, name) {
            return new Promise((resolve, reject) => {
                if (name) {
                    HTTP
                        .post("customer/update/name", {
                            name
                        })
                        .then(function (response) {
                            commit("auth/setUser", response.data, {root: true})
                            resolve(response.data);
                        })
                        .catch(function (response) {
                            reject(response);
                        })
                } else {
                    reject()
                }

            })
        },
        updatePhone: function ({commit}, data) {
            return new Promise((resolve, reject) => {
                if (data) {
                    HTTP
                        .post("customer/update/phone", data)
                        .then(function (response) {
                            commit("auth/setUser", response.data, {root: true})
                            resolve(response.data);
                        })
                        .catch(function (response) {
                            reject(response);
                        })
                } else {
                    reject()
                }

            })
        },
        updateImage: function ({commit}, image) {
            return new Promise((resolve, reject) => {
                if (image) {
                    let formData = new FormData();
                    formData.append('image', image);
                    HTTP
                        .post("customer/update/image", formData)
                        .then(function (response) {
                            commit("auth/setUser", response.data, {root: true})
                            resolve(response.data);
                        })
                        .catch(function (response) {
                            reject(response);
                        })
                } else {
                    reject()
                }

            })
        },
        updateFcm: function ({rootGetters}, token) {
            return new Promise((resolve, reject) => {
                if (token) {
                    HTTP
                        .post("customer/fcm", {
                            token,
                            device_id: rootGetters['temp/getDeviceId'](),
                        })
                        .then(function (response) {
                            resolve(response.data);
                        })
                        .catch(function (response) {
                            reject(response);
                        })
                } else {
                    reject()
                }

            })
        },
        delete: function () {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("customer/delete")
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
    }
}
