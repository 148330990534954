import {HTTP} from '@shared/plugins/axios'

export const CustomersPersonalitiesModule = {
    namespaced: true,
    state: () => ({
        list: null,
        personalities: null,
        new_list: [],
        deleted_list: [],
    }),
    getters: {
        getList: (state) => () => {
            return state.list
        },
        getPersonalities: (state) => (query) => {
            if (query) {
                return state.personalities.filter(el => el.name.indexOf(query) !== -1);
            }
            return state.personalities;
        },
    },
    mutations: {
        setList: function (state, list) {
            state.list = list;
            state.new_list = [];
            state.deleted_list = [];
        },
        setNewList: function (state, list) {
            state.new_list = list;
        },
        setPersonalities: function (state, personalities) {
            state.personalities = personalities;
        },
        addItem: function (state, item) {
            state.list.push(item);
            state.new_list.push(item);
        },
        removeItem: function (state, index) {
            state.deleted_list.push(state.list.splice(index, 1)[0]);
        },
    },
    actions: {
        loadList: function ({commit}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .get("customer-personality/get")
                    .then(function (response) {
                        commit("setList", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        loadPersonalities: function ({commit}, query) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("/criteria/personalities", {
                        query: query || ''
                    })
                    .then(function (response) {
                        commit("setPersonalities", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
        save: function ({commit, state}) {
            return new Promise((resolve, reject) => {
                HTTP
                    .post("customer-personality/save", {
                        personalities_new: state.new_list,
                        personalities_deleted: state.deleted_list
                    })
                    .then(function (response) {
                        commit("setList", response.data)
                        resolve();
                    })
                    .catch(function (response) {
                        reject(response);
                    })
            })
        },
    }
}
